import * as React from 'react';
import styled from 'styled-components';
import useTypedSelector from '@/hooks/useTypedSelector';
import HeaderNavigationProps from '@/interfaces/HeaderNavigation';

const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
                                                             active,
                                                             handleClick
                                                           }): React.ReactElement => {
  const { role } = useTypedSelector((state) => state.user);

  const handleClickElement = (y: number) => {
    handleClick();

    setTimeout(() => {
      window.scroll(0, y);
    }, 100);
  };

  return (
    <HeaderNavigationContainer active={active}>
      <HeaderNav>
        <HeaderNavList>

          <HeaderNavListItem>
            <HeaderNavListText
              onClick={() => handleClickElement(role === 'user' ? 780 : role === 'coach' ? 290 : 580)}
            >
              Почему выбирают нас
            </HeaderNavListText>
          </HeaderNavListItem>

          <HeaderNavListItem>
            <HeaderNavListText
              onClick={() => handleClickElement(role === 'user' ? 1650 : role === 'coach' ? 1150 : 1850)}
            >
              Результаты наших тренеров
            </HeaderNavListText>
          </HeaderNavListItem>

          <HeaderNavListItem>
            <HeaderNavListText
              onClick={() => handleClickElement(role === 'user' ? 2200 : role === 'coach' ? 1700 : 2400)}
            >
              Возможности Личного кабинета пользователя
            </HeaderNavListText>
          </HeaderNavListItem>

          <HeaderNavListItem
            onClick={() => handleClickElement(role === 'user' ? 2950 : role === 'coach' ? 2450 : 3130)}
          >
            <HeaderNavListText>Тарифы</HeaderNavListText>
            <HeaderRatesList>
              <HeaderRatesListItem>
                <HeaderRatesLogo src={'/assets/img/headerRates/rates1.svg'} />
                <HeaderRatesText>Похудение</HeaderRatesText>
              </HeaderRatesListItem>
              <HeaderRatesListItem>
                <HeaderRatesLogo src={'/assets/img/headerRates/rates2.svg'} />
                <HeaderRatesText>Набор мышечной массы</HeaderRatesText>
              </HeaderRatesListItem>
              <HeaderRatesListItem>
                <HeaderRatesLogo src={'/assets/img/headerRates/rates4.svg'} />
                <HeaderRatesText>Лечебная физкультура</HeaderRatesText>
              </HeaderRatesListItem>
            </HeaderRatesList>
          </HeaderNavListItem>

          <HeaderNavListItem>
            <HeaderNavListText
              onClick={() => handleClickElement(role === 'user' ? 4550 : role === 'coach' ? 4050  : 4750)}
            >
              Каталог тренеров
            </HeaderNavListText>
          </HeaderNavListItem>


          <HeaderNavListItem>
            <HeaderNavListText
              onClick={() => handleClickElement(role === 'user' ? 5400 : role === 'coach' ? 4900 : 5600)}
            >
              Часто задаваемые вопросы
            </HeaderNavListText>
          </HeaderNavListItem>

          {/*<HeaderNavListItem*/}
          {/*  onClick={() => handleClickElement(role === 'user' ? 5550 : 5050)}*/}
          {/*>*/}
          {/*  <HeaderNavListText>Тренерам</HeaderNavListText>*/}
          {/*</HeaderNavListItem>*/}

          <HeaderNavListItem onClick={() => handleClickElement(role === 'user' ? 6350 : role === 'coach' ? 5850 : 6580)}>
            <HeaderNavListText>О нас</HeaderNavListText>
          </HeaderNavListItem>

        </HeaderNavList>
      </HeaderNav>
      <HeaderNavigationVkWrapper href="https://vk.com/sportadium">
        <VkLogo src={'/vk-ogo.svg'} />
      </HeaderNavigationVkWrapper>
      <HeaderNavigationEmail href={'sportadium@yandex.ru'}>
        sportadium@yandex.ru
      </HeaderNavigationEmail>
    </HeaderNavigationContainer>
  );
};

const HeaderNavigationContainer = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 390px;
    min-width: 320px;
    min-height: 100vh;
    background: #020028 url('/assets/img/background/backgroundTop.png') no-repeat;
    padding: 80px 0 0 25px;
    transition: 0.4s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: ${({ active }) => (active ? '1' : '0')};
    transform: ${({ active }) => (active ? 'translateX(0)' : 'translateX(100%)')};

    @media (min-width: 375px) {
        padding: 80px 37px 0 37px;
    }
`;

const HeaderNav = styled.nav``;

const HeaderNavList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 40px 0;
`;

const HeaderNavListItem = styled.li`
`;

const HeaderNavListText = styled.p`
    font-size: 17px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.32px;
    text-transform: uppercase;

    @media (min-width: 390px) {
        font-size: 18px;
    }
`;

const HeaderRatesList = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 16px 0;
`;

const HeaderRatesListItem = styled.li`
    display: flex;
    align-items: center;
    margin: 8px 0;

    :nth-child(1) {
        margin-top: 0px;
    }

    :nth-child(4) {
        margin-bottom: 0px;
    }
`;

const HeaderRatesText = styled.p`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.8px;
`;

const HeaderRatesLogo = styled.img`
    display: block;
    margin: 0 16px 0 0;
`;

const HeaderNavigationVkWrapper = styled.a`
    display: flex;
    width: 100%;
    height: auto;
    max-width: 36px;
    min-height: 36px;
    margin: 0 0 10px 0;
`;

const VkLogo = styled.img`
    display: flex;
    width: 100%;
    height: auto;
    min-height: 100%;
    max-width: 100%;
    object-fit: cover;
`;

const HeaderNavigationEmail = styled.a`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.8px;
`;

export default HeaderNavigation;
