import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import { OrderPayload } from '@/store/Order/types';
import GET_ORDER_URL from '@/store/Order/api';

const postOrders = createAsyncThunk(
  'orders',
  async (payload: OrderPayload, _) => {

    try {
      return await request({
        url: GET_ORDER_URL,
        method: 'POST',
        payload
      });
    } catch (error) {
      console.log(error);
    }
  }
);

export { postOrders };
