import React from 'react';
import Script from 'next/script';
import { YANDEX_METRIKA_KEY } from '@/constants/api';

const Metrika = () => (
  <>
    <Script
      id="yandex-metrika-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          (function(m,e,t,r,i,k,a){
            m[i]=m[i]||function(){
              (m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();
            k=e.createElement(t),a=e.getElementsByTagName(t)[0];
            k.async=1;k.src=r;a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

          ym(${YANDEX_METRIKA_KEY}, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
          });
        `
      }}
    />
    <noscript>
      <div>
        <img src={`https://mc.yandex.ru/watch/${YANDEX_METRIKA_KEY}`} style={{ position: 'absolute', left: '-9999px' }}
             alt="" />
      </div>
    </noscript>
  </>
);

export default Metrika;
