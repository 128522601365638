import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import GET_USER_WEIGHTS_URL, {
  GET_PERIOD_USER_WEIGHTS_URL,
  POST_USER_WEIGHTS_URL,
} from '@/store/Weights/api';
import {
  UserWeightsPayload,
  UserWeightsPostPayload,
} from '@/store/Weights/types';

//Action для получения информации о весе пользователя
const getUserWeights = createAsyncThunk(
  'getUserWeights',
  async (payload: UserWeightsPayload, _) => {
    try {
      return await request({
        url: GET_USER_WEIGHTS_URL,
        method: 'POST',
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const getPeriodWeights = createAsyncThunk(
  'getPeriodWeights',
  async (payload: UserWeightsPayload, _) => {
    try {
      return await request({
        url: GET_PERIOD_USER_WEIGHTS_URL,
        method: 'POST',
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для добавлении информации о весе пользователя
const postUserWeights = createAsyncThunk(
  'postUserWeights',
  async (payload: UserWeightsPostPayload, _) => {
    try {
      return await request({
        url: POST_USER_WEIGHTS_URL,
        method: 'POST',
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

export { getUserWeights, getPeriodWeights, postUserWeights };
