import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import GET_USER_TYPES_PARAMS_URL, {
  GET_USER_PARAMS_URL,
  POST_USER_PARAMS_URL,
} from '@/store/BodySize/api';
import {
  PostUserParamsPayload,
  UserParamsPayload,
  UserTypesPayload,
} from '@/store/BodySize/types';

//Action для получения всех замеров
const getUserTypesParams = createAsyncThunk(
  'getUserTypes',
  async (payload: UserTypesPayload) => {
    try {
      return await request({
        url: GET_USER_TYPES_PARAMS_URL,
        method: 'POST',
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для получения всех замеров определенного типа
const getUserParams = createAsyncThunk(
  'getUserParams',
  async (payload: UserParamsPayload) => {
    try {
      return await request({
        url: GET_USER_PARAMS_URL,
        method: 'POST',
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для добавления замера
const postUserParams = createAsyncThunk(
  'postUserParams',
  async (payload: PostUserParamsPayload) => {
    try {
      return await request({
        url: POST_USER_PARAMS_URL,
        payload,
        method: 'POST',
        loader: true,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

export { getUserTypesParams, getUserParams, postUserParams };
