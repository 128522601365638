import { SERVER_API_URL } from '@/constants/api';

const GET_COACHES_URL = `${SERVER_API_URL}/coaches/get`;
const GET_TARIFF_COACHES_URL = `${SERVER_API_URL}/coaches/get-tariff-coach`;
const GET_COACH_URL = `${SERVER_API_URL}/coaches/get_coach`;
const GET_COACH_STUDENTS_URL = `${SERVER_API_URL}/bearer/coaches/get_students`;

export { GET_COACH_URL, GET_COACH_STUDENTS_URL, GET_TARIFF_COACHES_URL };

export default GET_COACHES_URL;
