import { createSlice } from '@reduxjs/toolkit';
import {
  createDialog,
  getDialogInfo,
  getDialogs,
  getDialogMessages,
  sendMessage,
  readMessage,
} from '@/store/Dialogs/actions';
import DialogsState, { MessageItem } from '@/store/Dialogs/types';

const initialState: DialogsState = {
  //Все диалоги
  allDialogs: null,

  //Исспользуется при создании диалога
  createDialog: null,

  //Вся информация о диалоге
  dialogInfo: null,

  //Все сообщения конкретного диалога
  dialogMessages: [],

  //Используется , когда мы отправляем сообщение
  sending: null,

  //Получение конкретного сообщения
  message: null,
  messagePhoto: null,
  media: null,

  readStatus: null,
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    updateDialogMessages(state: DialogsState, { payload }) {
      state.message = payload;
      state.dialogMessages = [...state.dialogMessages, payload as MessageItem];
    },
    updateMedia(state: DialogsState, { payload }) {
      state.messagePhoto = payload;
    },
  },
  extraReducers: (builder) => {
    //Получение всех Диалогов
    builder.addCase(
      getDialogs.fulfilled,
      (state: DialogsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.allDialogs = data;
        }
      }
    );

    //Получение информации о конкретном диалоге
    builder.addCase(
      getDialogInfo.fulfilled,
      (state: DialogsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.dialogInfo = data;
        }
      }
    );

    //Получение сообщений конкретного диалога
    builder.addCase(
      getDialogMessages.fulfilled,
      (state: DialogsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.dialogMessages = data;
        }
      }
    );

    //Создание диалога , происходит при выборе тренера или смене тренера
    builder.addCase(
      createDialog.fulfilled,
      (state: DialogsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.createDialog = data;
        }
      }
    );

    builder.addCase(
      sendMessage.fulfilled,
      (state: DialogsState, { payload }: any) => {
        if (payload) {
          state.sending = payload.message;
        }
      }
    );

    builder.addCase(
      readMessage.fulfilled,
      (state: DialogsState, { payload }: any) => {
        if (payload) {
          state.readStatus = payload.message;
        }
      }
    );
  },
});

const { reducer, actions } = dialogsSlice;

export const { updateDialogMessages, updateMedia } = actions;

export {
  getDialogs,
  getDialogInfo,
  getDialogMessages,
  createDialog,
  sendMessage,
  readMessage,
};

export default reducer;
