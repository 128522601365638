import { createSlice } from '@reduxjs/toolkit';
import CoachState, { CoachItem } from '@/store/Coaches/types';
import {
  getCoach,
  getCoaches,
  getCoachStudents,
  getTariffCoaches
} from '@/store/Coaches/actions';

const initialState: CoachState = {
  coaches: [],
  coach: null,
  selectedCoach: null,
  meta: null,
  students: null
};

const coachesSlice = createSlice({
  name: 'coaches',
  initialState,
  reducers: {
    selectCouch(state, action) {
      state.selectedCoach = action.payload;
    }
  },
  extraReducers: (builder) => {
    //Редьюсер на получение всех тренеров
    builder.addCase(
      getCoaches.fulfilled,
      (state: CoachState, { payload }: any) => {
        const { data, meta } = payload;

        if (meta) {
          state.meta = meta;
        }

        if (data) {
          if (state.coaches.length === 0) {
            state.coaches = data;
          } else {
            const prevState = JSON.parse(JSON.stringify([...state.coaches]));
            const newState = [...data];

            prevState === newState ? state.coaches === prevState : state.coaches === [...state.coaches, ...data];
          }
        }
      }
    );

    //Редьюсер на получение всех тренеров
    builder.addCase(
      getTariffCoaches.fulfilled,
      (state: CoachState, { payload }: any) => {
        const { data, meta } = payload;

        if (meta) {
          state.meta = meta;
        }

        const newCoaches = data.filter((newCoach: CoachItem) => {
          return !state.coaches.some(
            (existingCoach: CoachItem) => existingCoach.id === newCoach.id
          );
        });

        state.coaches = [...state.coaches, ...newCoaches];
      }
    );

    //Редьюсер на получение конкретного тренера
    builder.addCase(
      getCoach.fulfilled,
      (state: CoachState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.coach = { ...data };
        }
      }
    );

    //Редьюсер на получение учеников
    builder.addCase(
      getCoachStudents.fulfilled,
      (state: CoachState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.students = { ...data };
        }
      }
    );
  }
});

const { reducer, actions } = coachesSlice;

export const { selectCouch } = actions;

export { getCoaches, getCoach, getCoachStudents, getTariffCoaches };

export default reducer;
