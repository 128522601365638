import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import Head from 'next/head';
import store from '@/store/index';
import { setAuth } from '@/store/Auth';
import PagePropsExtended from '@/interfaces/Page';
import Layout from '@/components/common/Layout';
import Metrika from '@/components/common/Metrika';

const AppRoot = ({ Component, pageProps, props }: PagePropsExtended) => {
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  useEffect(() => {
    store.dispatch(setAuth(props.isAuth));
  }, []);

  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="/favicon.png"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta name="yandex-verification" content="3e98ba1ba463e0c0" />
        <title>
          Sportadium – онлайн тренировки с персональными тренерами, которые знают, как похудеть быстро, эффективно и без
          вреда для здоровья, как набрать мышечную массу и как привести себя в форму
        </title>
      </Head>

      <Metrika />

      <Provider store={store}>
        {getLayout(
          <Component {...pageProps} />
        )}
      </Provider>
    </>
  );
};

AppRoot.getInitialProps = async ({ ctx }: any) => {
  const session = !!ctx.req?.cookies?.sportadium_session;

  return {
    props: {
      isAuth: session
    }
  };
};

export default AppRoot;
