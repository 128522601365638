import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import REGISTER_URL from '@/store/Register/api';
import { RegisterPayload } from '@/store/Register/types';

//Action для регистрации нового пользоваетля
const register = createAsyncThunk(
  'register',
  async (payload: RegisterPayload, _) => {
    try {
      return await request({ url: REGISTER_URL, payload, loader: true });
    } catch (error) {
      console.log(error);
    }
  }
);

export { register };
