import { createSlice } from '@reduxjs/toolkit';
import { getLevel } from '@/store/Level/actions';
import LevelState from '@/store/Level/types';

const initialState: LevelState = {
  level_training: [],
};

const tariffsSlice = createSlice({
  name: 'level',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Редьюсер для получение списка всех уровней подготовки
    builder.addCase(getLevel.fulfilled, (state, { payload }: any) => {
      const { data } = payload;

      if (data) {
        state.level_training = data;
      }
    });
  },
});

const { reducer } = tariffsSlice;

export { getLevel };

export default reducer;
