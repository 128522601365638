import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import { GET_USER_MEDIA_URL, DELETE_USER_MEDIA_URL } from '@/store/Media/api';
import { GetUserMediaPayload, DeleteUserMediaPayload } from '@/store/Media/types';

const getUserMedia = createAsyncThunk(
  'getUserMedia',
  async (payload: GetUserMediaPayload) => {
    try {
      return await request({ url: GET_USER_MEDIA_URL, method: 'POST', payload });
    } catch (error) {
      console.log(error);
    }
  }
);

const deleteUserMedia = createAsyncThunk('deleteUserMedia', async (payload: DeleteUserMediaPayload) => {
  try {
    return await request({ url: DELETE_USER_MEDIA_URL, method: 'POST', loader: true, payload });
  } catch (error) {
    console.log(error);
  }
});

export {
  getUserMedia,
  deleteUserMedia
};