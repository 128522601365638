import { createSlice } from '@reduxjs/toolkit';
import QuestionsState from '@/store/FAQ/types';
import { getQuestions } from '@/store/FAQ/actions';

const initialState: QuestionsState = {
  questions: []
};

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getQuestions.fulfilled,
      (state: QuestionsState, { payload }: any) => {

        if (payload) {
          state.questions = payload;
        }
      }
    );
  }
});

const { reducer } = questionsSlice;

export {
  getQuestions
};

export default reducer;