import Theme from '@/interfaces/Theme';
import { DARK_COLORS, LIGHT_COLORS } from '@/styles/theme/colors';

/**
 * Цветовая тема будет состоять из полей,
 * проименованных по принадлежности свойства:
 * colors, shadows, etc...
 * Для добавления нового поля необходимо создать
 * .ts файл в данной директории и импортировать
 * в данный файл.
 */

const THEME: Theme = {
  dark: {
    colors: DARK_COLORS,
  },
  light: {
    colors: LIGHT_COLORS,
  },
};

export default THEME;
