import React, { ReactElement } from 'react';
import styled from 'styled-components';
import LoaderComponent from '@/components/common/Loader/LoaderComponent';

const Loader: React.FC = (): ReactElement => {
  return (
    <LoaderWrapper>
      <LoaderComponent />
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
`;

export default Loader;
