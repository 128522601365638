import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import WorkSheetState from '@/store/WorkSheet/types';
import { UserWorkSheetStep } from '@/enums/userWorkSheet';

const initialState: WorkSheetState = {
  step: null,
};

const workSheetSlice = createSlice({
  name: 'worksheet',
  initialState,
  reducers: {
    toggleStep: (state, { payload }: PayloadAction<UserWorkSheetStep>) => {
      state.step = payload;
    },
  },
});

const { actions, reducer } = workSheetSlice;

export const { toggleStep } = actions;

export default reducer;
