import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import GET_TARIFFS_URL, { GET_TARIFF_URL } from '@/store/Tariffs/api';
import { TariffPayload } from '@/store/Tariffs/types';

//Action для получения всех тарифов
const getTariffs = createAsyncThunk('getTariffs', async () => {
  try {
    return await request({ url: GET_TARIFFS_URL, method: 'GET' });
  } catch (error) {
    console.log(error);
  }
});

//Action получения инофрмации о тарифе пользователя
const getUserTariff = createAsyncThunk(
  'getUserTariff',
  async (payload: TariffPayload, _) => {
    try {
      return await request({ url: GET_TARIFF_URL, method: 'POST', payload });
    } catch (error) {
      console.log(error);
    }
  }
);

export { getTariffs, getUserTariff };
