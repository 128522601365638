import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import { RecoveryPayload } from '@/store/Recovery/types';
import RECOVERY_URL from '@/store/Recovery/api';

//Action для сборса пароля
const postRecovery = createAsyncThunk(
  'recovery',
  async (payload: RecoveryPayload, _) => {
    try {
      return await request({ url: RECOVERY_URL, payload, loader: true });
    } catch (error) {
      console.log(error);
    }
  }
);

export { postRecovery };
