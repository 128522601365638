import { SERVER_API_URL } from '@/constants/api';

const GET_DIALOGS_URL = `${SERVER_API_URL}/bearer/dialogs/get`;
const GET_DIALOG_MESSAGES_URL = `${SERVER_API_URL}/bearer/dialogs/get-dialog-messages`;
const CREATE_DIALOG_URL = `${SERVER_API_URL}/bearer/dialogs/post`;
const POST_MESSAGE_URL = `${SERVER_API_URL}/bearer/dialogs/post-dialog-message`;
const POST_MEDIA_URL = `${SERVER_API_URL}/media/upload/post-media`;
const POST_READ_MESSAGE_URL = `${SERVER_API_URL}/bearer/dialogs/read`;

export {
  CREATE_DIALOG_URL,
  GET_DIALOG_MESSAGES_URL,
  POST_MESSAGE_URL,
  POST_MEDIA_URL,
  POST_READ_MESSAGE_URL,
};

export default GET_DIALOGS_URL;
