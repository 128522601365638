import {
  combineReducers,
  configureStore,
  PreloadedState
} from '@reduxjs/toolkit';
import auth from '@/store/Auth';
import account from '@/store/Account';
import user from '@/store/User';
import theme from '@/store/Theme';
import register from '@/store/Register';
import login from '@/store/Login';
import recovery from '@/store/Recovery';
import loader from '@/store/Loader';
import worksheet from '@/store/WorkSheet';
import tariffs from '@/store/Tariffs';
import coaches from '@/store/Coaches';
import level from '@/store/Level';
import weights from '@/store/Weights';
import userParams from '@/store/BodySize';
import dialogs from '@/store/Dialogs';
import orders from '@/store/Order';
import FAQ from '@/store/FAQ';
import media from '@/store/Media';

const rootReducer = combineReducers({
  theme,
  register,
  login,
  recovery,
  auth,
  account,
  user,
  loader,
  worksheet,
  tariffs,
  coaches,
  level,
  weights,
  dialogs,
  userParams,
  orders,
  FAQ,
  media
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      }),
    preloadedState
  });
};

const store = setupStore();

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
