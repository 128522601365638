import { SERVER_API_URL } from '@/constants/api';

const GET_USER_URL = `${SERVER_API_URL}/bearer/user/get`;
const GET_CERTAIN_USER = `${SERVER_API_URL}/bearer/user/get-user`;
const SET_USER_URL = `${SERVER_API_URL}/bearer/user/set`;
const SET_USER_AVATAR_URL = `${SERVER_API_URL}/bearer/user/set-user-avatar`;
const SET_USER_TARIFF_URL = `${SERVER_API_URL}/bearer/user/select-tariff/set`;
const SET_USER_COACH_URL = `${SERVER_API_URL}/bearer/user/select-coach/set`;
const SET_USER_LEVEL_URL = `${SERVER_API_URL}/bearer/user/level-training/set`;
const LOGOUT_USER_URL = `${SERVER_API_URL}/bearer/user/logout`;
const LAST_UPDATE_URL = `${SERVER_API_URL}/bearer/user/last-update`;


export {
  GET_CERTAIN_USER,
  SET_USER_URL,
  SET_USER_TARIFF_URL,
  SET_USER_COACH_URL,
  SET_USER_LEVEL_URL,
  SET_USER_AVATAR_URL,
  LOGOUT_USER_URL,
  LAST_UPDATE_URL
};

export default GET_USER_URL;
