import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Theme from '@/store/Theme/types';
import ThemeType from '@/enums/theme';

const initialState: Theme = {
  theme: ThemeType.Dark,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state: Theme, { payload }: PayloadAction<ThemeType>) => {
      state.theme = payload;
    },
  },
});

const { actions, reducer } = themeSlice;

export const { toggleTheme } = actions;

export default reducer;
