import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import GET_USER_URL, {
  SET_USER_COACH_URL,
  SET_USER_LEVEL_URL,
  SET_USER_TARIFF_URL,
  SET_USER_URL,
  LOGOUT_USER_URL,
  LAST_UPDATE_URL,
  GET_CERTAIN_USER
} from '@/store/User/api';
import {
  CertainUserPayload,
  LastUpdatePayload,
  SetUserCoachPayload,
  SetUserHealthPayload,
  SetUserLevelPayload,
  SetUserPayload,
  SetUserTariffPayload
} from '@/store/User/types';
import ContentTypeEnum from '@/enums/contentType';

//Action для получения информации о пользователе
const getUser = createAsyncThunk('getUser', async () => {
  try {
    return await request({ url: GET_USER_URL, method: 'GET' });
  } catch (error) {
    console.log(error);
  }
});

const getCertainUser = createAsyncThunk(
  'getCertainUser',
  async (payload: CertainUserPayload) => {
    try {
      return await request({ url: GET_CERTAIN_USER, method: 'POST', payload });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для изменения информации о пользователе
const setUser = createAsyncThunk('setUser', async (payload: SetUserPayload) => {
  try {
    return await request({ url: SET_USER_URL, method: 'PUT', payload });
  } catch (error) {
    console.log(error);
  }
});

//Action для изменения информации о тарифе пользователя
const setUserTariff = createAsyncThunk(
  'setUserTariff',
  async (payload: SetUserTariffPayload) => {
    try {
      return await request({
        url: SET_USER_TARIFF_URL,
        method: 'PUT',
        payload,
        loader: true
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для изменения информации о тренере пользователя
const setUserCoach = createAsyncThunk(
  'setUserCoach',
  async (payload: SetUserCoachPayload) => {
    try {
      return await request({
        url: SET_USER_COACH_URL,
        method: 'PUT',
        payload
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для изменения информации о здоровье пользователя
const setUserHealth = createAsyncThunk(
  'setUserHealth',
  async (payload: SetUserHealthPayload) => {
    try {
      return await request({
        url: SET_USER_URL,
        method: 'PUT',
        payload,
        loader: true
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для изменения информации о уровне подготовки пользователе
const setUserLevel = createAsyncThunk(
  'setUserLevel',
  async (payload: SetUserLevelPayload) => {
    try {
      return await request({
        url: SET_USER_LEVEL_URL,
        method: 'PUT',
        payload,
        loader: true
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const uploadUserAvatar = createAsyncThunk(
  'UploadUserAvatar',
  async (payload: FormData) => {
    try {
      const UPLOAD_AVATAR = 'https://bg.sportadium.ru/api/user/update-avatar';

      return await request({
        url: UPLOAD_AVATAR,
        method: 'POST',
        payload,
        loader: true,
        contentType: ContentTypeEnum.FormData
      });
    } catch (error) {
      console.log(error);
    }
  }
);

const logOut = createAsyncThunk('logOut', async () => {
  try {
    return await request({
      url: LOGOUT_USER_URL,
      method: 'POST',
      loader: true
    });
  } catch (error) {
    console.log(error);
  }
});

const lastUpdate = createAsyncThunk(
  'lastUpdate',
  async (payload: LastUpdatePayload) => {
    try {
      return await request({
        url: LAST_UPDATE_URL,
        method: 'POST',
        payload
      });
    } catch (error) {
      console.log(error);
    }
  }
);

export {
  getUser,
  getCertainUser,
  setUser,
  setUserTariff,
  setUserCoach,
  setUserHealth,
  setUserLevel,
  uploadUserAvatar,
  logOut,
  lastUpdate
};
