import styled from 'styled-components';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 0;
  margin: 0;
  padding: 0;
`;

export default FlexContainer;
