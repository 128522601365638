import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import GET_COACHES_URL, {
  GET_COACH_STUDENTS_URL,
  GET_COACH_URL,
  GET_TARIFF_COACHES_URL,
} from '@/store/Coaches/api';
import {
  CoachesPayload,
  CoachPayload,
  TariffCoachesPayload,
} from '@/store/Coaches/types';

//Action для получения всех тренеров
const getCoaches = createAsyncThunk(
  'getCoaches',
  async (payload: CoachesPayload, _) => {
    try {
      return await request({
        url: GET_COACHES_URL,
        method: 'POST',
        loader: payload.loader,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для получения всех тренеров
const getTariffCoaches = createAsyncThunk(
  'getTariffCoaches',
  async (payload: TariffCoachesPayload, _) => {
    try {
      return await request({
        url: GET_TARIFF_COACHES_URL,
        method: 'POST',
        loader: payload.loader,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для получения конректного тренера
const getCoach = createAsyncThunk(
  'getCoach',
  async (payload: CoachPayload, _) => {
    try {
      return await request({ url: GET_COACH_URL, method: 'POST', payload });
    } catch (error) {
      console.log(error);
    }
  }
);

const getCoachStudents = createAsyncThunk(
  'getCoachStudents',
  async (payload: CoachPayload, _) => {
    try {
      return await request({
        url: GET_COACH_STUDENTS_URL,
        method: 'POST',
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

export { getCoaches, getTariffCoaches, getCoach, getCoachStudents };
