import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import FlexContainer from '@/components/ui/FlexContainer';
import useTypedSelector from '@/hooks/useTypedSelector';

const Footer: React.FC = (): React.ReactElement => {
  const router = useRouter();
  const { role } = useTypedSelector((state) => state.user);

  const handleClickFooter = (y: number): void => {
    setTimeout(() => {
      window.scroll(0, y);
    }, 100);
  };

  const handleClickFooterTerms = (url: string): void => {
    router.push(url);
  };


  return (
    <FooterWrapper>
      <FlexContainer>
        <FooterList>
          <FooterListItem
            id={'0'}
            onClick={() => handleClickFooter(role === 'user' ? 780 : role === 'coach' ? 290 : 580)}
            key={'sportsmen-info'}
          >
            Почему выбирают нас
          </FooterListItem>


          <FooterListItem
            id={'1'}
            onClick={() => handleClickFooter(role === 'user' ? 1650 : role === 'coach' ? 1150 : 1850)}
            key={'result'}
          >
            Результаты тренеров
          </FooterListItem>

          <FooterListItem
            id={'2'}
            onClick={() => handleClickFooter(role === 'user' ? 2200 : role === 'coach' ? 1700 : 2400)}
            key={'preview'}
          >
            Возможности личного кабинента пользователя
          </FooterListItem>

          <FooterListItem
            id={'3'}
            onClick={() => handleClickFooter(role === 'user' ? 5400 : role === 'coach' ? 4900 : 5600)}
            key={'faq'}
          >
            Часто задавемые вопросы
          </FooterListItem>

          {/*<FooterListItem*/}
          {/*  id={'3'}*/}
          {/*  onClick={() => handleClickFooter(role === 'user' ? 5550 : 3800)}*/}
          {/*  key={'couch'}*/}
          {/*>*/}
          {/*  Тренерам*/}
          {/*</FooterListItem>*/}


          <FooterListItem
            id={'4'}
            onClick={() => handleClickFooter(role === 'user' ? 6350 : role === 'coach' ? 5850 : 6580)}
            key={'about-us'}
          >
            О нас
          </FooterListItem>

          <FooterListItem
            id={'5'}
            onClick={() => handleClickFooterTerms('/contacting')}
            key={'contacting'}
          >
            Обратная связь
          </FooterListItem>

          <FooterListItem
            id={'6'}
            onClick={() => handleClickFooterTerms('/sportsmen-terms')}
            key={'terms'}
          >
            Пользовательские соглашения
          </FooterListItem>
        </FooterList>
      </FlexContainer>
      <VkWrapper onClick={() => handleClickFooterTerms('https://vk.com/sportadium')}>
        <VkImage src={'/vk-text.svg'} />
      </VkWrapper>
      <BanksInfoWrapper>
        <BanksInfoImage src={'/assets/banks/tinkoff.png'} />
      </BanksInfoWrapper>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
    width: 100%;
    padding: 18px 20px 30px 20px;
    border-top: 0.5px solid transparent;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    position: relative;

    :before {
        content: '';
        width: 286px;
        height: 0.5px;
        background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) -1.12%,
                #ffffff 49.97%,
                rgba(255, 255, 255, 0) 100%
        );
        position: absolute;
        top: 0;
        right: calc(50% - 140px);
    }
`;

const FooterList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 4px 45px;
    margin: 0 0 20px 0;
    gap: 5px;
`;

const FooterListItem = styled.li`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
`;

const VkWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 160px;
    margin: 0 auto 20px auto;
    padding: 10px;
    background: #fff;
    border-radius: 14px;
`;

const BanksInfoWrapper = styled.div`
    display: flex;
    width: 50px;
    height: 50px;
    margin: 0 auto;
`;

const BanksInfoImage = styled.img`
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
`;

const VkImage = styled(BanksInfoImage)``;

export default Footer;
