import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import { LoginPayload } from '@/store/Login/types';
import LOGIN_URL from '@/store/Login/api';

//Action для авторизации пользователя
const login = createAsyncThunk('login', async (payload: LoginPayload, _) => {
  try {
    return await request({ url: LOGIN_URL, payload, loader: true });
  } catch (error) {
    console.log(error);
  }
});

export { login };
