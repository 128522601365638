import { createSlice } from '@reduxjs/toolkit';
import UserState from '@/store/User/types';
import {
  getUser,
  setUser,
  setUserCoach,
  setUserTariff,
  setUserHealth,
  setUserLevel,
  uploadUserAvatar,
  lastUpdate,
  getCertainUser
} from '@/store/User/actions';

const initialState: UserState = {
  birth_date: null,
  coach_id: null,
  relation_id: null,
  created_at: null,
  email: null,
  email_verified_at: null,
  gender: null,
  id: null,
  name: '',
  surname: null,
  tariff_id: null,
  updated_at: null,
  worksheet: null,
  online: false,
  health: {
    bad_habits: '',
    chronic_diseases: '',
    comment: '',
    food_restriction: '',
    injuries: ''
  },
  level_training: null,
  level_training_id: null,
  tariff: null,
  tariff_days: null,
  tariff_time: null,
  tariff_time_format: null,
  avatar: null,
  role: null,
  certain: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetWorksheetStatus(state, { payload }) {
      state.worksheet = payload;
    },
    updateAvatar(state, { payload }) {
      state.avatar = payload;
    }
  },
  extraReducers: (builder) => {
    //Редьюсер для получения информации о пользователе
    builder.addCase(getUser.fulfilled, (state: UserState, { payload }: any) => {
      const { data } = payload;

      if (data) {
        state.birth_date = data.birth_date;
        state.coach_id = data.coach_id;
        state.created_at = data.created_at;
        state.email = data.email;
        state.email_verified_at = data.email_verified_at;
        state.gender = data.gender;
        state.id = data.id;
        state.name = data.name;
        state.surname = data.surname;
        state.tariff = data.tariff;
        state.tariff_days = data.tariff_days;
        state.tariff_time_format = data.tariff_time_format;
        state.tariff_time = data.tariff_time;
        state.tariff_id = data.tariff_id;
        state.updated_at = data.updated_at;
        state.worksheet = data.worksheet;
        state.health = data.health;
        state.level_training = data.level_training;
        state.level_training_id = data.level_training_id;
        state.role = data.role;
        state.avatar = data.avatar;
        state.relation_id = data.relation_id;
      }
    });

    builder.addCase(
      getCertainUser.fulfilled,
      (state: UserState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.certain = data;
        }
      }
    );

    //Редьюсер для изменения информации о пользователе
    builder.addCase(setUser.fulfilled, (state: UserState, { payload }: any) => {
      const { data } = payload;

      if (data) {
        state.birth_date = data.birth_date;
        state.coach_id = data.coach_id;
        state.created_at = data.created_at;
        state.email = data.email;
        state.email_verified_at = data.email_verified_at;
        state.gender = data.gender;
        state.id = data.id;
        state.name = data.name;
        state.role = data.role;
        state.surname = data.surname;
        state.tariff = data.tariff;
        state.tariff_days = data.tariff_days;
        state.tariff_time = data.tariff_time;
        state.tariff_id = data.tariff_id;
        state.updated_at = data.updated_at;
        state.worksheet = data.worksheet;
        state.health = data.health;
        state.level_training = data.level_training;
        state.level_training_id = data.level_training_id;
        state.avatar = data.avatar;
        state.relation_id = data.relation_id;
      }
    });

    //Редьюсер для изменения информации о тарифе пользователя
    builder.addCase(
      setUserTariff.fulfilled,
      (state: UserState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.birth_date = data.birth_date;
          state.coach_id = data.coach_id;
          state.created_at = data.created_at;
          state.email = data.email;
          state.email_verified_at = data.email_verified_at;
          state.gender = data.gender;
          state.id = data.id;
          state.name = data.name;
          state.role = data.role;
          state.surname = data.surname;
          state.tariff = data.tariff;
          state.tariff_days = data.tariff_days;
          state.tariff_time = data.tariff_time;
          state.tariff_id = data.tariff_id;
          state.updated_at = data.updated_at;
          state.worksheet = data.worksheet;
          state.health = data.health;
          state.level_training = data.level_training;
          state.level_training_id = data.level_training_id;
          state.avatar = data.avatar;
          state.relation_id = data.relation_id;
        }
      }
    );

    //Редьюсер для изменения информации о тренере пользователя
    builder.addCase(
      setUserCoach.fulfilled,
      (state: UserState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.birth_date = data.birth_date;
          state.coach_id = data.coach_id;
          state.created_at = data.created_at;
          state.email = data.email;
          state.email_verified_at = data.email_verified_at;
          state.gender = data.gender;
          state.id = data.id;
          state.name = data.name;
          state.role = data.role;
          state.surname = data.surname;
          state.tariff = data.tariff;
          state.tariff_days = data.tariff_days;
          state.tariff_time = data.tariff_time;
          state.tariff_id = data.tariff_id;
          state.updated_at = data.updated_at;
          state.worksheet = data.worksheet;
          state.health = data.health;
          state.level_training = data.level_training;
          state.level_training_id = data.level_training_id;
          state.relation_id = data.relation_id;
        }
      }
    );

    builder.addCase(
      setUserHealth.fulfilled,
      (state: UserState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.health = data.health;
        }
      }
    );

    //Редьюсер для изменения информации о уровне подготовки пользователя
    builder.addCase(
      setUserLevel.fulfilled,
      (state: UserState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.birth_date = data.birth_date;
          state.coach_id = data.coach_id;
          state.created_at = data.created_at;
          state.email = data.email;
          state.email_verified_at = data.email_verified_at;
          state.gender = data.gender;
          state.id = data.id;
          state.name = data.name;
          state.role = data.role;
          state.surname = data.surname;
          state.tariff = data.tariff;
          state.tariff_days = data.tariff_days;
          state.tariff_time = data.tariff_time;
          state.tariff_id = data.tariff_id;
          state.updated_at = data.updated_at;
          state.worksheet = data.worksheet;
          state.health = data.health;
          state.level_training = data.level_training;
          state.level_training_id = data.level_training_id;
          state.relation_id = data.relation_id;
        }
      }
    );

    builder.addCase(
      uploadUserAvatar.fulfilled,
      (state: UserState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.avatar = data.avatar;
        }
      }
    );

    builder.addCase(
      lastUpdate.fulfilled,
      (state: UserState, { payload }: any) => {
        if (payload) {
          state.online = payload;
        }
      }
    );
  }
});

const { reducer, actions } = userSlice;

export const { resetWorksheetStatus, updateAvatar } = actions;

export {
  getCertainUser,
  setUser,
  setUserHealth,
  setUserLevel,
  setUserCoach,
  uploadUserAvatar,
  lastUpdate
};

export default reducer;
