import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import useTypedSelector from '@/hooks/useTypedSelector';
import HeaderNavigation from '@/components/ui/HeaderNavigation';
import BaseButton from '@/components/ui/BaseButton';

const Header: React.FC = (): React.ReactElement => {
  const { role, worksheet } = useTypedSelector((state) => state.user);
  const [active, setActive] = useState<boolean>(false);
  const router = useRouter();

  //Обработчик события для Burger menu
  const handleClickBurger = (): void => {
    active
      ? document.body.classList.remove('hidden')
      : document.body.classList.add('hidden');
    setActive(!active);
  };

  const handleClick = (): void => {
    active
      ? document.body.classList.remove('hidden')
      : document.body.classList.add('hidden');
    setActive(!active);
  };

  const handleClickLogin = (): void => {
    setTimeout(() => {
      router.push('/auth/login');
    }, 50)
  };

  const handleClickUserLogo = (): void => {
    role === 'user' ? router.push('/account') : router.push('/coach-account');
  };

  return (
    <HeaderWrapper>
      <LogoHeader src={'/assets/img/logo/logo.png'} />
      <HeaderUI>
        {worksheet ? (
          <UserLogo active={active} onClick={() => handleClickUserLogo()} />
        ) : (
          <LoginButton onClick={() => handleClickLogin()}>Вход</LoginButton>
        )}
        <BurgerButton active={active} onClick={() => handleClickBurger()}>
          <span></span>
        </BurgerButton>
      </HeaderUI>
      <HeaderNavigation active={active} handleClick={handleClick} />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
    display: flex;
    justify-content: space-between;
    min-width: 320px;
    max-width: 390px;
    padding: 15px 23px;
    background-image: url('/assets/img/background/backgroundMainPageHead.png');
    background-repeat: no-repeat;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
`;

const LogoHeader = styled.img`
    display: block;
    width: 30px;
    height: 40px;
    z-index: 1001;

    @media (min-width: 390px) {
        width: 40px;
        height: 50px;
    }
`;


const HeaderUI = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0 6px 0;
`;

const UserLogo = styled.a<{ active: boolean }>`
    display: block;
    width: 16px;
    height: 17px;
    transition: 0.3s ease;
    background-image: url('/assets/img/logo/union.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: ${({ active }) => (active ? '0' : '1')};
    cursor: pointer;
`;

const BurgerButton = styled.button<{ active: boolean }>`
    width: 26px;
    height: 25px;
    display: flex;
    position: relative;
    border: none;
    background: transparent;
    cursor: pointer;
    margin: 0 0 0 20px;
    transition: all 0.3s ease-in;
    z-index: 1001;

    :before {
        content: '';
        position: absolute;
        top: ${({ active }) => (active ? '12px' : '0px')};
        width: 100%;
        height: 3px;
        transform: ${({ active }) => (active ? 'rotate(45deg)' : 'rotate(0deg)')};
        background: #ffffff;
        transition: 0.3s ease;
    }

    :after {
        content: '';
        position: absolute;
        bottom: ${({ active }) => (active ? '10px' : '0px')};
        width: 100%;
        height: 3px;
        background: #ffffff;
        transform: ${({ active }) => (active ? 'rotate(-45deg)' : 'rotate(0deg)')};
        transition: 0.3s ease;
    }

    span {
        position: absolute;
        top: 45%;
        width: 100%;
        height: 3px;
        background: #fff;
        opacity: ${({ active }) => (active ? '0' : '1')};
        transition: 0.3s ease;
    }
`;

const LoginButton = styled(BaseButton)`
    width: 100px !important;
    height: 40px !important;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.25px;
    border-radius: 20px !important;
    transition: all 0.3s ease-in;

    &:before {
        border-radius: 20px;
    }
`;

export default Header;
