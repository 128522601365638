import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AccountState from '@/store/Account/types';
import { supportAppeal } from '@/store/Account/actions';

const initialState: AccountState = {
  appeal: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateStatusAppeal: (state: AccountState, { payload }) => {
      state.appeal = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      supportAppeal.fulfilled,
      (state: AccountState, { payload }: any) => {
        if (payload) {
          state.appeal = payload;
        }
      }
    );
  },
});

const { actions, reducer } = accountSlice;

export const { updateStatusAppeal } = actions;

export { supportAppeal };

export default reducer;
