import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LoaderState from '@/store/Loader/types';

const initialState: LoaderState = {
  loader: false,
};

const themeSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    toggleLoader: (state: LoaderState, { payload }: PayloadAction<boolean>) => {
      state.loader = payload;
    },
  },
});

const { actions, reducer } = themeSlice;

export const { toggleLoader } = actions;

export default reducer;
