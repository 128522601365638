import { createSlice } from '@reduxjs/toolkit';
import UserParamsState, {
  UserParamsItem,
  UserTypesItem
} from '@/store/BodySize/types';
import {
  getUserParams,
  getUserTypesParams,
  postUserParams
} from '@/store/BodySize/actions';
import UserWeightsState, { UserWeightsItems } from '@/store/Weights/types';

const initialState: UserParamsState = {
  typesList: null,
  userParams: [],
  newParams: null
};

const userParamsSlice = createSlice({
  name: 'userParams',
  initialState,
  reducers: {
    updateParamsValue(state: UserParamsState, { payload }) {
      state.userParams = [...state.userParams, payload as UserParamsItem];
    }
  },
  extraReducers: (builder) => {
    //Редьюсер для получения всех замеров
    builder.addCase(
      getUserTypesParams.fulfilled,
      (state: UserParamsState, { payload }: any) => {
        if (payload) {
          state.typesList = payload;
        }
      }
    );

    builder.addCase(
      getUserParams.fulfilled,
      (state: UserParamsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.userParams = data;
        }
      }
    );

    builder.addCase(
      postUserParams.fulfilled,
      (state: UserParamsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.newParams = data;
        }
      }
    );
  }
});

const { reducer, actions } = userParamsSlice;

export const { updateParamsValue } = actions;

export { getUserTypesParams, getUserParams, postUserParams };

export default reducer;
