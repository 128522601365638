import { createSlice } from '@reduxjs/toolkit';
import OrderState from '@/store/Order/types';
import { postOrders } from '@/store/Order/actions';

const initialState: OrderState = {
  orderId: null,
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Редьюсер для создания ордера
    builder.addCase(postOrders.fulfilled, (state, { payload }: any) => {
      if (payload) {
        state.orderId = payload.order_id;
      }
    });
  },
});

const { reducer } = orderSlice;

export { postOrders };

export default reducer;
