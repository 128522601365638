import { createSlice } from '@reduxjs/toolkit';
import TariffsState from '@/store/Tariffs/types';
import { getTariffs, getUserTariff } from '@/store/Tariffs/actions';

const initialState: TariffsState = {
  tariffs: [],
  tariff: null,
};

const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Редьюсер для получения всех тарифов
    builder.addCase(
      getTariffs.fulfilled,
      (state: TariffsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.tariffs = data;
        }
      }
    );

    //Редьюсер для получения инофрмации о тарифе пользователя
    builder.addCase(
      getUserTariff.fulfilled,
      (state: TariffsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.tariff = { ...data };
        }
      }
    );
  },
});

const { reducer } = tariffsSlice;

export { getTariffs };

export default reducer;
