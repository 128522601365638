import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import { SupportAppealPayload } from '@/store/Account/types';
import SUPPORT_APPEAL_URL from '@/store/Account/api';

const supportAppeal = createAsyncThunk(
  'support',
  async (payload: SupportAppealPayload, _) => {
    try {
      return await request({
        url: SUPPORT_APPEAL_URL,
        method: 'POST',
        payload,
        loader: true,
      });
    } catch (error) {
      console.log(error);
    }
  }
);

export { supportAppeal };
