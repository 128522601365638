import { createSlice } from '@reduxjs/toolkit';
import { deleteUserMedia, getUserMedia } from '@/store/Media/actions';
import { UserMediaState } from '@/store/Media/types';

const initialState: UserMediaState = {
  items: [],
  status: null
};

const userMediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    updateUserMedia(state: UserMediaState, { payload }) {
      state.items = state.items.filter(item => item.id !== payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserMedia.fulfilled, (state: UserMediaState, { payload }: any) => {
      const { data } = payload;

      if (data) {
        state.items = [...data];
      }
    });

    builder.addCase(deleteUserMedia.fulfilled, (state: UserMediaState, { payload }: any) => {

      if (payload) {
        state.status = payload.message;
      }
    });
  }
});

const { reducer, actions } = userMediaSlice;

const { updateUserMedia } = actions;

export {
  getUserMedia,
  deleteUserMedia,
  updateUserMedia
};

export default reducer;
