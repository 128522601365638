enum ThemeType {
  Light = 'light',
  Dark = 'dark',
}

export enum WebViewThemeType {
  Black = 'black',
  White = 'white',
}

export default ThemeType;
