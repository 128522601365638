import { css } from 'styled-components';

export default css`
    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Thin.ttf') format('woff');
        /* Дополнительные форматы шрифтов могут быть добавлены по необходимости */
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Light.ttf') format('woff');
        /* Дополнительные форматы шрифтов могут быть добавлены по необходимости */
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Regular.ttf') format('woff');
        /* Дополнительные форматы шрифтов могут быть добавлены по необходимости */
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Medium.ttf') format('woff');
        /* Дополнительные форматы шрифтов могут быть добавлены по необходимости */
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-SemiBold.ttf') format('woff');
        /* Дополнительные форматы шрифтов могут быть добавлены по необходимости */
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Bold.ttf') format('woff');
        /* Дополнительные форматы шрифтов могут быть добавлены по необходимости */
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-ExtraBold.ttf') format('woff');
        /* Дополнительные форматы шрифтов могут быть добавлены по необходимости */
        font-weight: 800;
        font-style: normal;
    }

    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Black.ttf') format('woff');
        /* Дополнительные форматы шрифтов могут быть добавлены по необходимости */
        font-weight: 900;
        font-style: normal;
    }

    * {
        outline: none;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        color: #fff;
    }

    html,
    body {
        width: 100%;
        height: auto;
        min-height: 100svh;
        margin: 0;
        background: #020028;
        font-family: 'Montserrat', sans-serif;
        scroll-behavior: smooth;
        position: relative;
        overflow-x: hidden;

        ::-webkit-scrollbar {
            width: 6px;
            height: 4px;
        }

        ::-webkit-scrollbar-button {
            width: 0;
            height: 0;
        }

        ::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.2);
            border: 0 none #ffffff;
            border-radius: 50px;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
            border: 0 none #ffffff;
            border-radius: 50px;
        }

        ::-webkit-scrollbar-corner {
            background: transparent;
        }
    }

    a {
        text-decoration: none;
        color: #fff;
    }

    ul {
        list-style: none;
    }

    #__next {
        width: 100%;
        max-width: 390px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        position: relative;
    }
`;
