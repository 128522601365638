/**
 * Именование цветовой пары происходит
 * по стандарту color[первые 3 символа цвета для темной темы]x[первые 3 символа цвета для светлой темы]
 * Пример: color000xfff
 *
 * Цвета формата rgba именуются
 * по стандарту rgbaColor[первые 2 числа вета для темной темы]x[первые 2 символа цвета для светлой темы],
 * alpha канал в название цвета не входит
 * Пример: rgbaColor24x18
 *
 * P.S. Если первые 3 символа совпадают с предыдущими цветами, то берем последние 3
 */

export const DARK_COLORS = {
  dark221xe66: '#221E66',
  dark363x187: '#363187',
  dark020x028: '#020028',
};

export const LIGHT_COLORS = {
  light635xdb8: '#635DB8',
  light1A8x6c2: '#1A86C2',
};
