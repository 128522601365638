import { createSlice } from '@reduxjs/toolkit';
import RegisterState from '@/store/Register/types';
import { register } from '@/store/Register/actions';

const initialState: RegisterState = {
  registered: false,
  errors: null,
};

const registerSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Редьюсер для регистрации нового пользователя
    builder.addCase(
      register.fulfilled,
      (state: RegisterState, { payload }: any) => {
        const { errors, auth } = payload;

        if (errors) {
          state.errors = errors;
        }

        if (auth) {
          state.registered = auth;
        }
      }
    );
  },
});

const { reducer } = registerSlice;

export { register };

export default reducer;
