export enum UserWorkSheetStatus {
  Processing = 'processing',
  Filled = 'filled',
}

export enum UserWorkSheetStep {
  ProfileCompletion = 'profile_completion',
  TariffSelection = 'tariff_selection',
  CoachChoice = 'сoach_сhoice',
}
