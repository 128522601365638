import React, { ReactElement } from 'react';
import styled from 'styled-components';

const LoaderComponent: React.FC = (): ReactElement => {
  return (
    <Loader className={'load'}>
      <hr />
      <hr />
      <hr />
      <hr />
    </Loader>
  );
};

const Loader = styled.div`
  &.load {
    width: 40px;
    height: 40px;

    hr {
      border: 0;
      margin: 0;
      width: 20px;
      height: 20px;
      position: absolute;
      border-radius: 50%;
      animation: spin 2s ease infinite;

      :first-child {
        background: #b23acc;
        animation-delay: -1.5s;
      }

      :nth-child(2) {
        background: #11b5eb;
        animation-delay: -1s;
      }

      :nth-child(3) {
        background: #4495ff;
        animation-delay: -0.5s;
      }

      :last-child {
        background: #6772ff;
      }
    }
  }

  @keyframes spin {
    0%,
    100% {
      transform: translate(0);
    }
    25% {
      transform: translate(160%);
    }
    50% {
      transform: translate(160%, 160%);
    }
    75% {
      transform: translate(0, 160%);
    }
  }
`;

export default LoaderComponent;
