import { createSlice } from '@reduxjs/toolkit';
import {
  getPeriodWeights,
  getUserWeights,
  postUserWeights,
} from '@/store/Weights/actions';
import UserWeightsState, { UserWeightsItems } from '@/store/Weights/types';

const initialState: UserWeightsState = {
  user_weights: [],
  new_user_weights: null,
  period_weights: [],
  period: [
    { id: '0', value: '1 год', period: 'year' },
    { id: '1', value: '6 мес.', period: '6_months' },
    { id: '2', value: '3 мес.', period: '3_months' },
    { id: '3', value: '1 мес.', period: 'month' },
    { id: '4', value: '1 нед.', period: 'week' },
  ],
};

const coachesSlice = createSlice({
  name: 'weights',
  initialState,
  reducers: {
    updateValue(state: UserWeightsState, { payload }) {
      state.user_weights = [...state.user_weights, payload as UserWeightsItems];
    },
  },
  extraReducers: (builder) => {
    //Редьюсер для получения информации о весе пользователя
    builder.addCase(
      getUserWeights.fulfilled,
      (state: UserWeightsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.user_weights = data;
        }
      }
    );

    //Редьюсер для получения информации о весе пользователя
    builder.addCase(
      getPeriodWeights.fulfilled,
      (state: UserWeightsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.period_weights = data;
        }
      }
    );

    //Редьюсер для добавлении информации о весе пользователя
    builder.addCase(
      postUserWeights.fulfilled,
      (state: UserWeightsState, { payload }: any) => {
        const { data } = payload;

        if (data) {
          state.new_user_weights = data;
        }
      }
    );
  },
});

const { reducer, actions } = coachesSlice;

export const { updateValue } = actions;

export { getUserWeights, getPeriodWeights, postUserWeights };

export default reducer;
