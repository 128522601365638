import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import { GET_QUESTIONS_URL } from '@/store/FAQ/api';

const getQuestions = createAsyncThunk('getQuestions', async () => {
  try {
    return await request({
      url: GET_QUESTIONS_URL,
      method: 'GET'
    });
  } catch (error) {
    console.log(error);
  }
});

export {
  getQuestions
};