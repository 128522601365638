import React from 'react';
import styled from 'styled-components';
import ContentProps from '@/interfaces/Content';

const Content: React.FC<ContentProps> = ({ children }): React.ReactElement => {
  return <ContentWrapper>{children}</ContentWrapper>;
};

const ContentWrapper = styled.main`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
`;

export default Content;
