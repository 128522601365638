import RecoveryState from '@/store/Recovery/types';
import { createSlice } from '@reduxjs/toolkit';
import { postRecovery } from '@/store/Recovery/actions';

const initialState: RecoveryState = {
  used: false,
  error: null,
  status: 'reset',
};

const recoverySlice = createSlice({
  name: 'recovery',
  initialState,
  reducers: {
    updateRecoveryState(state, { payload }) {
      state.status = payload;
    },
  },
  extraReducers: (builder) => {
    //Редьюсер для сборса пароля
    builder.addCase(
      postRecovery.fulfilled,
      (state: RecoveryState, { payload }) => {
        const { error } = payload || {};

        if (error) {
          state.error = error;
        }

        if (payload) {
          state.used = true;
        }
      }
    );
  },
});

const { reducer, actions } = recoverySlice;

export const { updateRecoveryState } = actions;

export { postRecovery };

export default reducer;
