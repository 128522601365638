import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import GET_LEVEL_URL from '@/store/Level/api';

//Action для получение списка всех уровней подготовки
const getLevel = createAsyncThunk('getLevel', async () => {
  try {
    return await request({ url: GET_LEVEL_URL, method: 'GET' });
  } catch (error) {
    console.log(error);
  }
});

export { getLevel };
