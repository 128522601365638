import RequestPayload from '@/interfaces/Request';
import store from '@/store/index';
import { toggleLoader } from '@/store/Loader';
import ContentTypeEnum from '@/enums/contentType';
import Cookies from 'js-cookie';

const request = async ({
  url,
  method = 'POST',
  payload = {},
  loader = false,
  contentType = ContentTypeEnum.ApplicationJson,
}: RequestPayload): Promise<any> => {
  try {
    const options: RequestInit = {
      method,
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      ...(method !== 'GET' && {
        body:
          contentType === ContentTypeEnum.ApplicationJson
            ? JSON.stringify(payload)
            : payload,
      }),
    };

    if (contentType === ContentTypeEnum.ApplicationJson) {
      options.headers = {
        'Content-Type': ContentTypeEnum.ApplicationJson,
      };
    }

    if (contentType === ContentTypeEnum.FormData) {
      const token = await Cookies.get('sportadium_session');
      options.headers = {
        'Content-Type': ContentTypeEnum.FormData,
        Authorization: `Bearer ${token}`,
      };
    }

    loader && store.dispatch(toggleLoader(true));

    const response = await fetch(url, options);

    return await response.json();
  } catch (e) {
    console.log(e);
  }
};

export default request;
