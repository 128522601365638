import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '@/services/request';
import GET_DIALOGS_URL, {
  CREATE_DIALOG_URL,
  GET_DIALOG_MESSAGES_URL,
  POST_MEDIA_URL,
  POST_MESSAGE_URL,
  POST_READ_MESSAGE_URL,
} from '@/store/Dialogs/api';
import {
  DialogPayload,
  MediaPayload,
  MessagePayload,
  MessagesPayload,
  SendMessagePayload,
  UnreadMessagePayload,
} from '@/store/Dialogs/types';

//Action для получения всех диалогов
const getDialogs = createAsyncThunk('getDialogs', async () => {
  try {
    return await request({ url: GET_DIALOGS_URL, method: 'GET' });
  } catch (error) {
    console.log(error);
  }
});

//Action для получения информации о конкретном диалоге
const getDialogInfo = createAsyncThunk(
  'getDialogInfo',
  async (payload: MessagesPayload) => {
    try {
      return await request({
        url: GET_DIALOGS_URL,
        payload,
        method: 'POST',
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для получения всех сообщений конкретного диалога
const getDialogMessages = createAsyncThunk(
  'getDialogMessages',
  async (payload: MessagesPayload) => {
    try {
      return await request({
        url: GET_DIALOG_MESSAGES_URL,
        payload,
        method: 'POST',
      });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для получения конкретного сообщения
const getMessage = createAsyncThunk(
  'getMessage',
  async (payload: MessagePayload) => {
    try {
      return await request({ url: POST_MESSAGE_URL, payload, method: 'POST' });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для создания нового диалога
const createDialog = createAsyncThunk(
  'createDialog',
  async (payload: DialogPayload) => {
    try {
      return await request({ url: CREATE_DIALOG_URL, payload, method: 'POST' });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для отправления сообщения
const sendMessage = createAsyncThunk(
  'sendMessage',
  async (payload: SendMessagePayload) => {
    try {
      return await request({ url: POST_MESSAGE_URL, payload, method: 'POST' });
    } catch (error) {
      console.log(error);
    }
  }
);

//Action для отправления сообщения
const sendMedia = createAsyncThunk(
  'sendMedia',
  async (payload: MediaPayload) => {
    try {
      return await request({ url: POST_MEDIA_URL, payload, method: 'POST' });
    } catch (error) {
      console.log(error);
    }
  }
);

const readMessage = createAsyncThunk(
  'readMessage',
  async (payload: UnreadMessagePayload) => {
    try {
      return await request({
        url: POST_READ_MESSAGE_URL,
        payload,
        method: 'POST',
      });
    } catch (error) {
      console.log(error);
    }
  }
);

export {
  getDialogs,
  getDialogInfo,
  getDialogMessages,
  getMessage,
  createDialog,
  sendMessage,
  sendMedia,
  readMessage,
};
