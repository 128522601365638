import { createSlice } from '@reduxjs/toolkit';
import AuthState from '@/store/Auth/types';

const initialState: AuthState = {
  auth: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, { payload }) {
      state.auth = payload;
    },
  },
});

const { reducer, actions } = authSlice;

export const { setAuth } = actions;

export default reducer;
