import LoginState from '@/store/Login/types';
import { createSlice } from '@reduxjs/toolkit';
import { login } from '@/store/Login/action';

const initialState: LoginState = {
  logined: false,
  errors: null,
  loginedError: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Редьюсер для авторизации пользователя
    builder.addCase(login.fulfilled, (state: LoginState, { payload }: any) => {
      const { errors, auth } = payload;

      if (errors) {
        state.errors = errors;
      }

      if (auth) {
        state.logined = auth;
      }

      if (auth === false) {
        state.loginedError = auth;
      }
    });
  },
});

const { reducer } = loginSlice;

export { login };

export default reducer;
